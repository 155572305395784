
import {
    inject,
    defineComponent,
    toRefs,
    ref,
    reactive,
    watch,
    toRef,
    Ref,
    PropType,
} from "vue";

export default defineComponent({
    components: {

    },
    props: {

    },
    emits: ["submit"],
    setup(props, { emit }) {
        const review = ref({
            result: true,
            memo: ""
        });
        const isLoading = ref(false);
        const doConfirm = () => {
            isLoading.value = true;
            emit("submit", review.value);
        }

        return {
            review,
            doConfirm,
            isLoading
        };
    },
});
